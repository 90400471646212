import {AppBar, Box, Button, Toolbar} from "@mui/material";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';

const menuItems = [
    "Startseite", "Historien", "Artikel", "Analyse", "Profil"
]

const menuIcons = [
    <HomeOutlinedIcon/>, <ManageSearchOutlinedIcon/>, <ArticleOutlinedIcon/>,
    <AutoGraphOutlinedIcon/>, <Person2OutlinedIcon/>
]

export default function Nav(props) {
    return (
        <AppBar position="static" color={"transparent"} sx={{
            boxShadow: "1 1 1 1",
            bgcolor: "lightGrey"
        }}>
            <Toolbar>
                {/* menu items */}
                <Box flexGrow={1}/>
                <Box sx={{
                    display: 'flex',
                    flexGrow: "1",
                    flexShrink: "1",
                    flexBasis: "0",
                }}>
                {menuItems.map((item, index) => (
                    <Button
                        key={index}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label={item}
                        variant={"outlined"}
                        sx={{mx: 2,
                            flexBasis: "100%",
                            '&:hover': {
                                backgroundColor: 'grey.100',
                            },
                            // if current selection
                            ...(props.currentSelection === index && {
                                backgroundColor: 'grey.100',
                            }),
                        }}
                        onClick={() => props.setCurrentSelection(index)}
                    >
                        {menuIcons[index]}
                        <Box mr={1}/>
                        {item}
                    </Button>
                ))}
                </Box>
                <Box flexGrow={1}/>
            </Toolbar>
        </AppBar>
    )
}