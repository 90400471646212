import {
    Box,
    Chip,
    Paper,
    Skeleton,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import {connect, useDispatch} from "react-redux";
import {useState, useEffect} from "react";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import keycloak from "../keycloak/Keycloak";
import {fetchHistories, historyActions} from "../util/store/history.slice";
import DatePickerDialog from "../components/historien/DatePickerDialog";

const mapStateToProps = (state) => {
    return {
        histories: state.histories.histories,
        fetchedDates: state.histories.fetchedDates,
        loading: state.histories.loading,
        error: state.histories.error,
    };
};

function Historien(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [currentDate, setCurrentDate] = useState(
        new Date().toISOString().split("T")[0]
    );
    const [datePickerDialogOpen, setDatePickerDialogOpen] = useState(false);
    const [selected, setSelected] = useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openDatePicker = () => {
        setDatePickerDialogOpen(true);
    };

    const actions = [
        {
            icon: (
                <CalendarMonthOutlinedIcon
                    color={props.loading ? "disabled" : "primary"}
                />
            ),
            name: "Datum wählen",
            onClick: () => {
                if (!props.loading) openDatePicker();
            },
        },
        {
            icon: (
                <ModeEditOutlineOutlinedIcon
                    color={
                        selected && keycloak.hasResourceRole("edit-history")
                            ? "primary"
                            : "disabled"
                    }
                />
            ),
            name: selected
                ? keycloak.hasResourceRole("edit-history")
                    ? "Bearbeiten"
                    : "Bearbeiten (Keine Berechtigung)"
                : "Bearbeiten (Reihe wählen, um zu bearbeiten)",
            onClick: () => {
                if (selected && keycloak.hasResourceRole("edit-history")) {
                    console.log("edit");
                }
            },
        },
        {
            icon: (
                <DeleteForeverOutlinedIcon
                    color={
                        selected && keycloak.hasResourceRole("delete-history")
                            ? "error"
                            : "disabled"
                    }
                />
            ),
            name: selected
                ? keycloak.hasResourceRole("delete-history")
                    ? "Löschen"
                    : "Löschen (Keine Berechtigung)"
                : "Löschen (Reihe wählen, um zu löschen)",
            onClick: () => {
                if (selected && keycloak.hasResourceRole("delete-history")) {
                    console.log("delete");
                }

            },
        },
    ];

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - props.histories.length)
            : 0;

    const dispatch = useDispatch();

    const [todaysHistories, setTodaysHistories] = useState([]);

    useEffect(() => {
        performAsyncDateChange(currentDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDate]);

    useEffect(() => {
        setTodaysHistories(
            props.histories.filter((history) => {
                    // check if history is from today, dont check on time
                    return history.date.split("T")[0] === currentDate;
                }
            ));
    }, [props.histories, currentDate]);

    const performAsyncDateChange = (date) => {
        if (!props.loading && !props.fetchedDates.includes(date)) {
            // check if already loaded
            dispatch(fetchHistories(date));
            dispatch(historyActions.addDate(date));
        }
    }

    const skeletonRow = (
        <TableRow>
            <TableCell>
                <Skeleton/>
            </TableCell>
            <TableCell>
                <Skeleton/>
            </TableCell>
            <TableCell>
                <Skeleton/>
            </TableCell>
            <TableCell>
                <Skeleton/>
            </TableCell>
            <TableCell>
                <Skeleton/>
            </TableCell>
            <TableCell>
                <Skeleton/>
            </TableCell>
        </TableRow>
    );

    const errorRow = (
        <TableRow>
            <TableCell colSpan={6} align="center">
                <Typography color="error" variant="h6">
                    Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
                </Typography>
            </TableCell>
        </TableRow>
    );

    return (
        <Box
            sx={{
                width: "100vw",
                height: "80vh",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "start",
            }}
        >
            <SpeedDial
                ariaLabel="Artikel Menü"
                sx={{
                    "& .MuiFab-primary": {width: 48, height: 48},
                }}
                icon={<SpeedDialIcon/>}
                direction="down"
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.onClick}
                    />
                ))}
            </SpeedDial>
            <Box sx={{width: "90%"}}>
                <TableContainer component={Paper}>
                    <Table aria-label="history table" align="center" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: "10%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Uhrzeit
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: "15%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Artikel
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: "15%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    User
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: "15%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Preis
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: "15%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Anzahl
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: "30%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Notiz
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                // if loading, show skeleton
                                props.loading
                                    ? skeletonRow
                                    : // if error, show error message
                                    props.error
                                        ? errorRow
                                        : // if no error and not loading, show data
                                        (rowsPerPage > 0
                                                ? todaysHistories.slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                )
                                                : todaysHistories
                                        ).map((history) => (
                                            <TableRow
                                                key={history.id}
                                                sx={{
                                                    "&:last-child td, &:last-child th": {border: 0},
                                                    // if selected row, change background color
                                                    backgroundColor:
                                                        selected === history ? "#e0e0e0" : "white",
                                                }}
                                                onClick={() => setSelected(history)}
                                            >
                                                <TableCell align="center">
                                                    {new Date(history.date).toLocaleTimeString("de-DE")}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {history.article ? history.article.articleCode : "-"}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Chip
                                                        label={history.username}
                                                    ></Chip>
                                                </TableCell>
                                                <TableCell align="center">{history.price}</TableCell>
                                                <TableCell align="center">{history.quantity}</TableCell>
                                                <TableCell align="center">
                                                    {history.note || "-"}
                                                </TableCell>
                                            </TableRow>
                                        ))
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{height: 53 * emptyRows}}>
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[
                                        10,
                                        20,
                                        50,
                                        {label: "Alle", value: -1},
                                    ]}
                                    colSpan={3}
                                    count={todaysHistories.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "rows per page",
                                        },
                                        native: true,
                                    }}
                                    labelRowsPerPage="Zeilen pro Seite"
                                    labelDisplayedRows={({from, to, count}) =>
                                        `${from}-${to} von ${
                                            count !== -1 ? count : `more than ${to}`
                                        }`
                                    }
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>

            {datePickerDialogOpen && (
                <DatePickerDialog
                    value={currentDate}
                    open={datePickerDialogOpen}
                    onClose={() => setDatePickerDialogOpen(false)}
                    onDateChange={(date) => {
                        setCurrentDate(date);
                    }}
                />
            )}
        </Box>
    );
}

export default connect(mapStateToProps)(Historien);
