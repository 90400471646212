import { combineReducers, configureStore } from "@reduxjs/toolkit";
import articlesSlice from "./article.slice";
import historySlice from "./history.slice";

export const reducer = combineReducers({
    articles: articlesSlice,
    histories: historySlice,
});

export const store = configureStore({
    reducer: reducer,
});