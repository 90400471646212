import './App.css';
import {useKeycloak} from "@react-keycloak/web";
import {Box, CircularProgress, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import Nav from "./components/Nav";
import Startseite from "./pages/Startseite";
import Historien from "./pages/Historien";
import Artikel from "./pages/Artikel";
import Analyse from "./pages/Analyse";
import Profil from "./pages/Profil";
import {useDispatch} from "react-redux";
import {fetchArticles} from "./util/store/article.slice";
import "./css/App.css";
import {fetchHistories, historyActions} from "./util/store/history.slice";

function App() {

    /**
     * The Keycloak instance.
     */
    const {keycloak, initialized} = useKeycloak();

    /**
     * Current selection in the navigation bar.
     */
    const [currentSelection, setCurrentSelection] = useState(0);

    /**
     * The Redux dispatch function.
     * @type {Dispatch<AnyAction>} dispatch
     */
    const dispatch = useDispatch();

    /**
     * If the user is not authenticated, redirect to the login page.
     */
    useEffect(() => {
        if (initialized) {
            if (!keycloak.authenticated) {
                keycloak.login();
            } else {
                initialFetch();
            }
        }
    }, [initialized, keycloak]);

    /**
     * Awaits the initial fetch of articles.
     */
    const initialFetch = () => {
        dispatch(fetchArticles());
        dispatch(fetchHistories(new Date().toISOString().split("T")[0]));
        dispatch(historyActions.addDate(new Date().toISOString().split("T")[0]));
    }

    /**
     * Wait until the Keycloak instance is initialized.
     */
    if (!initialized) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                flexDirection: "column"
            }}>
                <CircularProgress color={"primary"}/>
                <Typography
                    variant={"h6"}
                    sx={{mt: 2}}
                >
                    Initializing Application...
                </Typography>
            </Box>
        );
    }

    return (
        <div className="App">
            <Nav currentSelection={currentSelection} setCurrentSelection={setCurrentSelection}/>
            <Box sx={{
                mt: 2,
            }}>
                {/** The background circles */}
                <div className="area">
                    <ul className="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>

                {currentSelection === 0 && (
                    <Startseite/>
                )}
                {currentSelection === 1 && (
                    (keycloak.hasResourceRole("read-history")) ?
                        <Historien/> :
                        <Typography variant={"h6"} color={"red"}>Sie haben unzureichende Berechtigungen!</Typography>
                )}
                {(currentSelection === 2) && (
                    (keycloak.hasResourceRole("read-article")) ?
                        <Artikel/> :
                        <Typography variant={"h6"} color={"red"}>Sie haben unzureichende Berechtigungen!</Typography>
                )}
                {currentSelection === 3 && (
                    (keycloak.hasResourceRole("read-analytics")) ?
                        <Analyse/> :
                        <Typography variant={"h6"} color={"red"}>Sie haben unzureichende Berechtigungen!</Typography>
                )}
                {currentSelection === 4 && (
                    <Profil/>
                )}
            </Box>
        </div>
    );
}

export default App;
