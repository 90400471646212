import {
    AddCircleOutlineRounded,
    RemoveCircleOutlineRounded,
} from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    Divider,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDispatch } from "react-redux";
import keycloak from "../../keycloak/Keycloak";
import {articleActions} from "../../util/store/article.slice";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export default function AddArticlePopup(props) {
    const [articleCode, setArticleCode] = useState("");
    const [germanDescription, setGermanDescription] = useState("");
    const [englishDescription, setEnglishDescription] = useState("");
    const [hungarianDescription, setHungarianDescription] = useState("");
    const [kgPerBag, setKgPerBag] = useState(0);
    const [quantityPerBag, setQuantityPerBag] = useState(0);
    const [pricePerBag, setPricePerBag] = useState(0);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const submitArticle = async () => {
        if (articleCode <= 0) {
            return;
        }

        setLoading(true);

        var result = await fetch(
            `${baseUrl}/articles`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${keycloak.token}`,
                },
                body: JSON.stringify({
                    articleCode: articleCode,
                    germanDescription: germanDescription,
                    englishDescription: englishDescription,
                    hungarianDescription: hungarianDescription,
                    kgPerBag: kgPerBag,
                    piecesPerBag: quantityPerBag,
                    purchasePrice: pricePerBag,
                }),
            }
        )

        if (result.ok) {
            dispatch(
            articleActions.addArticle({
                articleCode: articleCode,
                germanDescription: germanDescription,
                englishDescription: englishDescription,
                hungarianDescription: hungarianDescription,
                kgPerBag: kgPerBag,
                piecesPerBag: quantityPerBag,
                purchasePrice: pricePerBag,
            }));
            props.setSnackbar({
                    open: true,
                    message: "Artikel erfolgreich hinzugefügt",
                    severity: "success",
                });
        } else {
            props.setSnackbar({
                open: true,
                message: "Artikel konnte nicht hinzugefügt werden",
                severity: "error",
            });
        }

        resetForm();
        setLoading(false);
        props.onClose();
    }

    const resetForm = () => {
        setArticleCode("");
        setGermanDescription("");
        setEnglishDescription("");
        setHungarianDescription("");
        setKgPerBag(0);
        setQuantityPerBag(0);
        setPricePerBag(0);
    };

    return (
        <Dialog
            open={props.open}
            onClose={() => {
                if (!loading) {
                    props.onClose();
                }
            }}
        >
            <Box sx={{ p: 2 }}>
                <Typography variant="h5">Neuer Artikel</Typography>
                <Divider sx={{ mt: 2 }} />
                <Box
                    sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                    }}
                >
                    <TextField
                        label="Artikelnummer"
                        variant="outlined"
                        fullWidth
                        value={articleCode}
                        onChange={(e) => {
                            setArticleCode(e.target.value);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                // focus next input field
                                document.getElementById("kgPerBag").focus();
                            }
                        }}
                        sx={{ mt: 2 }}
                        type="number"
                        placeholder="1234"
                        required
                        inputProps={{ min: 0, max: 9999, step: 1 }}
                        error={articleCode <= 0}
                    />
                    <TextField
                        id="kgPerBag"
                        label="Kg pro Beutel"
                        variant="outlined"
                        fullWidth
                        value={kgPerBag}
                        onChange={(e) => {
                            setKgPerBag(e.target.value);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                // focus next input field
                                document.getElementById("quantityPerBag").focus();
                            }
                        }}
                        sx={{ mt: 2 }}
                        type="number"
                        InputProps={{
                            inputProps: {
                                style: { textAlign: "center" },
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="increase kg per bag"
                                        onClick={() => {
                                            // prevent string concatenation
                                            setKgPerBag((prevKgPerBag) => prevKgPerBag + 1);
                                        }}
                                    >
                                        <AddCircleOutlineRounded />
                                    </IconButton>
                                </InputAdornment>
                            ),

                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="decrease kg per bag"
                                        onClick={() => {
                                            if (kgPerBag - 1 >= 0) {
                                                setKgPerBag((prevKgPerBag) => prevKgPerBag - 1);
                                            } else if (kgPerBag > 0) {
                                                setKgPerBag(0);
                                            }
                                        }}
                                    >
                                        <RemoveCircleOutlineRounded />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <TextField
                        label="Anzahl pro Beutel"
                        variant="outlined"
                        fullWidth
                        value={quantityPerBag}
                        onChange={(e) => {
                            setQuantityPerBag(e.target.value);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                // focus next input field
                                document.getElementById("pricePerBag").focus();
                            }
                        }}
                        sx={{ mt: 2 }}
                        type="number"
                        InputProps={{
                            inputProps: {
                                style: { textAlign: "center" },
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="increase amount per bag"
                                        onClick={() => {
                                            // prevent string concatenation
                                            setQuantityPerBag(
                                                (prevQuantityPerBag) => prevQuantityPerBag + 1
                                            );
                                        }}
                                    >
                                        <AddCircleOutlineRounded />
                                    </IconButton>
                                </InputAdornment>
                            ),

                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="decrease amount per bag"
                                        onClick={() => {
                                            if (quantityPerBag - 1 >= 0) {
                                                setQuantityPerBag(
                                                    (prevQuantityPerBag) => prevQuantityPerBag - 1
                                                );
                                            } else if (quantityPerBag > 0) {
                                                setQuantityPerBag(0);
                                            }
                                        }}
                                    >
                                        <RemoveCircleOutlineRounded />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label="Preis pro Beutel"
                        variant="outlined"
                        fullWidth
                        value={pricePerBag}
                        onChange={(e) => {
                            setPricePerBag(e.target.value);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                // focus next input field
                                document.getElementById("germanDescription").focus();
                            }
                        }}
                        sx={{ mt: 2 }}
                        type="number"
                        InputProps={{
                            inputProps: {
                                style: { textAlign: "center" },
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="increase price per bag"
                                        onClick={() => {
                                            // prevent string concatenation
                                            setPricePerBag((prevPricePerBag) => prevPricePerBag + 1);
                                        }}
                                    >
                                        <AddCircleOutlineRounded />
                                    </IconButton>
                                </InputAdornment>
                            ),

                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="decrease price per bag"
                                        onClick={() => {
                                            if (pricePerBag - 1 >= 0) {
                                                setPricePerBag(
                                                    (prevPricePerBag) => prevPricePerBag - 1
                                                );
                                            } else if (pricePerBag > 0) {
                                                setPricePerBag(0);
                                            }
                                        }}
                                    >
                                        <RemoveCircleOutlineRounded />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                <TextField
                    id="germanDescription"
                    label="Deutsche Beschreibung"
                    variant="outlined"
                    fullWidth
                    value={germanDescription}
                    onChange={(e) => {
                        setGermanDescription(e.target.value);
                    }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            // focus next input field
                            document.getElementById("englishDescription").focus();
                        }
                    }}
                    sx={{ mt: 2 }}
                    inputProps={{ maxLength: 100 }}
                    multiline
                    rows={2}
                    error={germanDescription.length > 100}
                />
                <TextField
                    id="englishDescription"
                    label="Englische Beschreibung"
                    variant="outlined"
                    fullWidth
                    value={englishDescription}
                    onChange={(e) => {
                        setEnglishDescription(e.target.value);
                    }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            // focus next input field
                            document.getElementById("hungarianDescription").focus();
                        }
                    }}
                    sx={{ mt: 2 }}
                    inputProps={{ maxLength: 100 }}
                    multiline
                    rows={2}
                    error={englishDescription.length > 100}
                />
                <TextField
                    id="hungarianDescription"
                    label="Ungarische Beschreibung"
                    variant="outlined"
                    fullWidth
                    value={hungarianDescription}
                    onChange={(e) => {
                        setHungarianDescription(e.target.value);
                    }}
                    sx={{ mt: 2 }}
                    inputProps={{ maxLength: 100 }}
                    multiline
                    rows={2}
                    error={hungarianDescription.length > 100}
                />
            </Box>
            <DialogActions>
                <Button
                    onClick={() => {
                        if (loading) return;
                        resetForm();
                        props.onClose();
                    }}
                    variant="outlined"
                    color="secondary"
                >
                    Abbrechen
                </Button>
                <Button onClick={submitArticle} variant="outlined" color="primary">
                    {loading ? "Lädt..." : "Hinzufügen"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
