import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Skeleton,
    Snackbar,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { Box } from "@mui/system";
import { useState } from "react";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import { connect, useDispatch } from "react-redux";
import {articleActions} from "../util/store/article.slice";
import keycloak from "../keycloak/Keycloak";
import AddArticlePopup from "../components/artikel/AddArticlePopup";
import EditArticlePopup from "../components/artikel/EditArticlePopup";

const mapStateToProps = (state) => {
    return {
        articles: state.articles.articles,
        loading: state.articles.loading,
        error: state.articles.error,
    };
};

function Artikel(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [selected, setSelected] = useState("");
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    const [dialog, setDialog] = useState({
        open: false,
        title: "",
        content: "",
        loading: false,
        onConfirm: () => {},
    });
    const [addArticleDialog, setAddArticleDialog] = useState(false);
    const [editArticleDialog, setEditArticleDialog] = useState(false);

    const dispatch = useDispatch();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const baseUrl = `${process.env.REACT_APP_API_URL}`;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - props.articles.length)
            : 0;

    const actions = [
        {
            icon: (
                <AddCircleOutlineOutlinedIcon
                    color={
                        keycloak.hasResourceRole("write-article") ? "success" : "disabled"
                    }
                />
            ),
            name: keycloak.hasResourceRole("write-article")
                ? "Hinzufügen"
                : "Hinzufügen (Keine Berechtigung)",
            onClick: () => {
                if (keycloak.hasResourceRole("write-article")) {
                    setAddArticleDialog(true);
                }
            },
        },
        {
            icon: (
                <ModeEditOutlineOutlinedIcon
                    color={
                        selected && keycloak.hasResourceRole("edit-article")
                            ? "primary"
                            : "disabled"
                    }
                />
            ),
            name: selected
                ? keycloak.hasResourceRole("edit-article")
                    ? "Bearbeiten"
                    : "Bearbeiten (Keine Berechtigung)"
                : "Bearbeiten (Reihe wählen, um zu bearbeiten)",
            onClick: () => {
                if (selected && keycloak.hasResourceRole("edit-article")) {
                    setEditArticleDialog(true);
                }
            },
        },
        {
            icon: (
                <DeleteForeverOutlinedIcon
                    color={
                        selected && keycloak.hasResourceRole("delete-article")
                            ? "error"
                            : "disabled"
                    }
                />
            ),
            name: selected
                ? keycloak.hasResourceRole("delete-article")
                    ? "Löschen"
                    : "Löschen (Keine Berechtigung)"
                : "Löschen (Reihe wählen, um zu löschen)",
            onClick: () => {
                if (selected && keycloak.hasResourceRole("delete-article")) {
                    deleteArticle(selected);
                }

            },
        },
        {
            icon: (
                <ImportExportOutlinedIcon
                    color={keycloak.hasResourceRole("write-article") ? "black" : "disabled"}
                />
            ),
            name: keycloak.hasResourceRole("write-article")
                ? "Import"
                : "Import (Keine Berechtigung)",
        },
    ];

    const skeletonRow = (
        <TableRow>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
            <TableCell>
                <Skeleton />
            </TableCell>
        </TableRow>
    );

    if (props.error) {
        return (
            <Box>
                <Typography variant="h4" color="error">
                    Es ist ein Fehler aufgetreten!
                </Typography>
                <Typography variant="h6" color="error">
                    Bitte kontaktieren Sie den Administrator!
                </Typography>
            </Box>
        );
    }

    const deleteArticle = (article) => {
        if (selected === "" || !keycloak.hasResourceRole("delete-article")) {
            return;
        }
        setDialog({
            open: true,
            title: "Artikel löschen",
            content: `Möchten Sie den Artikel ${article.articleCode} wirklich löschen? Alle zugehörigen Historien werden unwiderruflich gelöscht!`,
            onConfirm: async () => {
                setDialog({ loading: true });
                dispatch(articleActions.removeArticle(article));

                var result = await fetch(`${baseUrl}/articles/${article.articleCode}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + keycloak.token,
                    },
                });

                if (result.status === 200) {
                    setSnackbar({
                        open: true,
                        message: "Artikel erfolgreich gelöscht!",
                        severity: "success",
                    });
                } else {
                    setSnackbar({
                        open: true,
                        message: "Artikel konnte nicht gelöscht werden!",
                        severity: "error",
                    });
                }
                setDialog({ open: false, loading: false });
            },
        });
    };

    const onCloseDialog = () => {
        if (dialog.loading) {
            return;
        }
        setDialog({ open: false });
    };

    const confirmationDialog = (
        <Dialog open={dialog.open} onClose={onCloseDialog}>
            <DialogTitle>{dialog.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{dialog.content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialog} variant="outlined" color="secondary">
                    Abbrechen
                </Button>
                <Button
                    onClick={dialog.onConfirm}
                    autoFocus
                    variant="outlined"
                    color="primary"
                >
                    {dialog.loading ? "Lädt..." : "Bestätigen"}
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <Box
            sx={{
                width: "100vw",
                height: "80vh",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "start",
            }}
        >
            <SpeedDial
                ariaLabel="Artikel Menü"
                sx={{
                    "& .MuiFab-primary": { width: 48, height: 48 },
                }}
                icon={<SpeedDialIcon />}
                direction="down"
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.onClick}
                    />
                ))}
            </SpeedDial>

            <Box
                sx={{
                    width: "90%",
                }}
            >
                <TableContainer component={Paper}>
                    <Table aria-label="article table" align="center" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: "10%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Artikel Code
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: "20%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Artikel Beschreibung (eng.)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: "20%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Artikel Beschreibung (deu.)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: "20%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Artikel Beschreibung (ung.)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: "10%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Kg pro Bag
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: "10%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Stück pro Bag
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: "10%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Preis pro Bag
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.loading
                                ? skeletonRow
                                : (rowsPerPage > 0
                                        ? props.articles.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        : props.articles
                                ).map((article) => (
                                    <TableRow
                                        key={article.articleCode}
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                            // if selected row, change background color
                                            backgroundColor:
                                                selected === article ? "#e0e0e0" : "white",
                                        }}
                                        onClick={() => setSelected(article)}
                                    >
                                        <TableCell align="center">
                                            {article.articleCode}
                                        </TableCell>
                                        <TableCell align="center">
                                            {article.englishDescription}
                                        </TableCell>
                                        <TableCell align="center">
                                            {article.germanDescription}
                                        </TableCell>
                                        <TableCell align="center">
                                            {article.hungarianDescription}
                                        </TableCell>
                                        <TableCell align="center">{article.kgPerBag}</TableCell>
                                        <TableCell align="center">
                                            {article.piecesPerBag}
                                        </TableCell>
                                        <TableCell align="center">
                                            {article.purchasePrice}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[
                                        10,
                                        20,
                                        50,
                                        { label: "Alle", value: -1 },
                                    ]}
                                    colSpan={3}
                                    count={props.articles.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "rows per page",
                                        },
                                        native: true,
                                    }}
                                    labelRowsPerPage="Zeilen pro Seite"
                                    labelDisplayedRows={({ from, to, count }) =>
                                        `${from}-${to} von ${
                                            count !== -1 ? count : `more than ${to}`
                                        }`
                                    }
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ open: false, message: "" })}
            >
                <Alert
                    onClose={() => setSnackbar({ open: false, message: "" })}
                    severity={snackbar.severity}
                    sx={{ width: "100%" }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>

            {confirmationDialog}

            {addArticleDialog && (
                <AddArticlePopup
                    open={addArticleDialog}
                    onClose={() => setAddArticleDialog(false)}
                    setSnackbar={setSnackbar}
                />
            )}

            {editArticleDialog && selected && (
                <EditArticlePopup
                    open={editArticleDialog}
                    onClose={() => setEditArticleDialog(false)}
                    setSnackbar={setSnackbar}
                    article={selected}
                    setSelectedArticle={setSelected}
                />
            )}
        </Box>
    );
}

export default connect(mapStateToProps)(Artikel);
