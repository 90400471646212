import {Box} from "@mui/system";
import {Button, Link, Typography} from "@mui/material";

export default function Analyse() {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '1.5rem',
                width: {xs: '100%', sm: '50%'},
                bgcolor: 'lightGrey',
                p: '1rem',
                borderRadius: '1rem',
            }}>
                <Typography variant="h1">Analyse</Typography>
                < Typography variant="body" spacing={4}>
                    Die Analyse ist eine grafische Darstellung der Daten, die du
                    über die App erfasst hast. Sie ist in der Lage, dir
                    Zusammenhänge aufzuzeigen, die du vielleicht nicht erwartet
                    hättest. So kannst du zum Beispiel sehen, wie sich deine
                    Einnahmen im Laufe der Zeit entwickeln oder wie sich deine
                    Ausgaben aufteilen.
                </Typography>
                <Typography variant="body">
                    Die Analyse findet auf einer separaten Website statt. Dort
                    kannst du dich mit deinen Zugangsdaten anmelden und die
                    Daten betrachten.
                </Typography>
                <Link
                    href="https://grafana.a66-frachtagentur.de"
                    target="_blank"
                    rel="noopener"
                >
                    <Button variant="outlined" color={"secondary"}>Bringe mich zur Analyse</Button>
                </Link>
            </Box>
        </Box>
    );
}