import Keycloak from 'keycloak-js';

const keycloak = Keycloak({
    url: 'https://keycloak.a66-frachtagentur.de',
    realm: 'Shopmanager',
    clientId: 'shopmanager-client',
    onLoad: 'login-required',
    checkLoginIframe: false,
});

export default keycloak;